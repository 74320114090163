import * as React from 'react';
import certificatesService from "../services/certificatesService";
import {useSnackbar} from "notistack";
import { useSelector} from 'react-redux';
import orderServices from 'src/services/orderServices';
import calculateCreditPoints from 'src/utils/calculateCreditPoints';
import alertPage from 'src/services/alertPage';
import { groupBy } from 'lodash';

export const CertificatesContext = React.createContext({});

export const CertificatesContextProvider = ({ children }) => {
  const user = useSelector((state) => state.account.user);
  const {enqueueSnackbar} = useSnackbar();
  const [drawerStatus, setDrawerStatus] = React.useState(false); // Certificate Add/ Edit Drawer
  const [addedCertificate, setAddedCertificate] = React.useState(0); // Ref to trigger fetchData useEffect
  const [activeTab, setActiveTab] = React.useState(0); // NOT IN USE
  const [activeCertificateData, setActiveCertificateData] = React.useState({}); // Save data when clicked on Add or Edit Certificate
  const [isEdit, setIsEdit] = React.useState(false); // Store current mode for Certificate Drawer
  const [isEmailDialogOpen, setIsEmailDialogOpen] = React.useState(false);
  const [highlightedCertID, setHighlightedCertID] = React.useState(null);

  const [isFullAccess, setFull_Access] = React.useState(false);
  
  const [isLoading, setIsLoading] = React.useState(false); // Loading State till Certificates Data is fetched
  const [certificatesData, setCertificatesData] = React.useState([]); // In Certificates Tacker
  const [grantedLicenses, setGrantedLicenses] = React.useState([]); // In State License Monitoring Section
  const [CMECertificatesData, setCMECertificatesData] = React.useState([]); // In CME Compliance Tracker
  const [latestCertIds, setLatestCertIds] = React.useState([]);

  // For Dashboard Certificates Card
  const [generatedCertificates, setGeneratedCertificates] = React.useState([]); // User Generated Certificates
  const [addedCertificates, setAddedCertificates] = React.useState([]); // Certificates Added by User from Frontend
  const [oldCertificates, setOldCertificates] = React.useState([]); // Old User Generated Certificates

  const [creditPoints, setCreditPoints] = React.useState(0); // Total Credit Points of All CME Certificates

  // For Dashboard CME Compliance Card
  const [claimedCreditPoints, setClaimedCreditPoints] = React.useState(0); // Total of CME Certificates Credits having entered_from_frontend = 0
  const [userAddedCreditPoints, setUserAddedCreditPoints] = React.useState(0); // Total of CME Certificates Credits having entered_from_frontend = 1

  const categoriseCerts=(certificatesData)=>{
    const bls_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047807' && it['entered_from_frontend']==0) || []).reverse(); 
    const acls_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047791' && it['entered_from_frontend']==0) || []).reverse(); 
    const pals_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047799' && it['entered_from_frontend']==0) || []).reverse();
    // reversing array will get latest element on 0th index 
    let tempArr = [
      bls_all.length>0? bls_all[0].id:null,
      acls_all.length>0? acls_all[0].id:null,
      pals_all.length>0? pals_all[0].id:null,
    ] 
    // tempArr Array Contains all latest (Most Recent) Certificates of ACLS, BLS, PALS
    setLatestCertIds(tempArr)
  }

  const fetchDummyCertificates = async () => {
    const dummyCertificates = await certificatesService.getDummyCertificates();
    return dummyCertificates
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let certificatesData = await certificatesService.getUserCertificates();
      categoriseCerts(certificatesData);
      const data = certificatesData.sort(function (a, b) { return b.entered_from_frontend - a.entered_from_frontend }).reverse();
      setCertificatesData(data);

      // Filter only medtigo course provider cards
      const filteredCertificates = data.filter(item => item.entered_from_frontend === 0 && item.state === 'Education');

      // Group filtered certificates by certificate_name
      const groupedCertificates = groupBy(filteredCertificates, 'certificate_name');

      // Combine the certificate with the latest Date_of_Creation from each group
      const firstCertificates = Object.values(groupedCertificates).map(certificates =>
        certificates.sort((a, b) => new Date(b.Date_of_Creation) - new Date(a.Date_of_Creation))[0]
      );

      // Exclude the latest certificate for each course based on Date_of_Creation
      const oldCertificates = Object.values(groupedCertificates)
        .flatMap(certificates =>
          certificates
            .filter(cert => cert.entered_from_frontend === 0) // Exclude certificates with entered_from_frontend = 1
            .sort((a, b) => new Date(b.Date_of_Creation) - new Date(a.Date_of_Creation)) // Sort by Date_of_Creation in descending order
            .slice(1) // Exclude the latest certificate
        )
        .filter(item => !item.certificate_name.includes('License'));

      const states = ['Education', 'Health', 'Malpractice', 'Transcripts and Scores'];

      setGeneratedCertificates(firstCertificates);
      setAddedCertificates(data.filter(certificate => certificate.entered_from_frontend === 1 && states.includes(certificate.state)));
      setOldCertificates(oldCertificates);

    } catch (error) {
      console.log(error);
      setCertificatesData([]);
    }
    try {
      const { data: grantedData } = await alertPage.getGrantedActiveLicenses(
        user.id
      );
      setGrantedLicenses(grantedData.filter(item => item.entered_from_frontend == false));
    } catch (error) {
      console.log(error);
      setGrantedLicenses([]);
    }

    try {
      // Fetch CME Certificates
      const CMECertificatesData = await certificatesService.getUserCertificates('cme');
      setCMECertificatesData(CMECertificatesData);
      setCreditPoints(calculateCreditPoints(CMECertificatesData));
      setClaimedCreditPoints(calculateCreditPoints(CMECertificatesData.filter(item => item.entered_from_frontend === 0)));
      setUserAddedCreditPoints(calculateCreditPoints(CMECertificatesData.filter(item => item.entered_from_frontend === 1)));
    } catch (error) {
      console.log(error);
      setCMECertificatesData([]);
      setCreditPoints(0);
    }  
    setIsLoading(false);
  };

  const handleSendEmail = async (emails, sendACopy, isCME, shouldEmailCmeCredits,shouldEmailCertificate) => {
    try {
      if (isCME) {
        if (shouldEmailCmeCredits) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: true },
            activeCertificateData['id']
          );
        }
        if (shouldEmailCertificate) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: false },
            activeCertificateData['nonCMECertId']
          );
        }
        enqueueSnackbar('Email(s) sent to recipients.', {
          variant: 'success'
        });
        return;
      }
      await certificatesService.sendCertificateEmail(
        {
          recipients: emails.join(','),
          sendACopy,
          isCME:
            activeCertificateData['type'] === 'CME' ||
            activeCertificateData['type'] === 'cme'
        },
        activeCertificateData['id']
      );
      enqueueSnackbar('Email(s) sent to recipients.', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Oops! An error occurred while sending emails.', {
        variant: 'error'
      });
    }

  };

  // Fetch Certificates on Component Mount & whenever a new Certificate is added from frontend or system generated
  React.useEffect(() => {
    !drawerStatus && fetchData();
    drawerStatus && !isEdit && setActiveCertificateData({});
    drawerStatus && setHighlightedCertID(null);
  }, [addedCertificate]);

  // Update Total CME Credit Points every time a CME Certificate is either claimed or added from frontend
  React.useEffect(() => {
    setCreditPoints(calculateCreditPoints(CMECertificatesData));
  }, [CMECertificatesData]);

  React.useEffect(() => {
    fetchFullAccessOrder();
  }, [])

  const fetchFullAccessOrder = async () => {
    const isFullAccessOrder = await orderServices.isFullAccessOrder();
    setFull_Access(isFullAccessOrder);
  }

  const defaultContext = {
    drawerStatus,
    setDrawerStatus,
    fetchData,
    isLoading,
    setIsLoading,
    certificatesData,
    grantedLicenses,
    CMECertificatesData,
    generatedCertificates,
    addedCertificates,
    oldCertificates,
    latestCertIds,
    setLatestCertIds,
    setCreditPoints,
    creditPoints,
    claimedCreditPoints,
    userAddedCreditPoints,
    addedCertificate,
    setAddedCertificate,
    activeCertificateData,
    setActiveCertificateData,
    isEdit,
    setIsEdit,
    setIsEmailDialogOpen,
    isEmailDialogOpen,
    handleSendEmail,
    highlightedCertID,
    setHighlightedCertID,
    activeTab,
    setActiveTab,
    isFullAccess,
    fetchDummyCertificates
  };

  return (
    <CertificatesContext.Provider
      value={defaultContext}
    >
      {children}
    </CertificatesContext.Provider>
  );
};
