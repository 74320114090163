import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { navbarStyles } from '../SchedulingNavbar';

function ExpensesNavbar() {
  const classes = navbarStyles();
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('meal expense');

  const tabs = [
    { value: 'meal expense', label: 'Meal Expense', link: '/expenses/meal-expense' },
    { value: 'travel expense', label: 'Travel Expense', link: '/expenses/travel-expense' },
    { value: 'site expense', label: 'Site Expense', link: '/expenses/site-expense' },
    { value: 'past expense', label: 'Past Submissions', link: '/expenses/submissions' },
  ];

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.link === location.pathname);
    if (currentTab) {
      setCurrentTab(currentTab.value);
    }
  }, [location.pathname]);

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    const newTab = tabs.find(tab => tab.value === newValue);
    if (newTab) {
      history.push(newTab.link);
    }
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
        classes={{ indicator: classes.tabIndicator }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            className={classes.tab}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default ExpensesNavbar;
